var elementi = document.getElementsByClassName("cover");

var widthMobile = 768;
var currentWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;


if (currentWidth <= widthMobile) {
    elementi.forEach(element => {

        if (element.attributes["data-bg-mob"].textContent != "") {
            element.style.backgroundImage = "url(" + element.attributes["data-bg-mob"].value + ")";
        }else{
            element.style.backgroundImage = "url(" + element.attributes["data-bg-desk"].value + ")";
        }
    });
}
else {
    elementi.forEach(element => {
        element.style.backgroundImage = "url(" + element.attributes["data-bg-desk"].value + ")";
    });
}
